import React from "react";
import './ReviewActive.css';

const ReviewActive = ( { userImage, nameUserRu, messageUserRu, onClose } ) => {
    return (
        <div className="reviewActive_container">
            <div className="reviewActive_contentBlock">
                <div className="reviewActive_title">
                    <div className="reviewActive_div"></div>
                    <div></div>
                    <div className="reviewActive_div"><button onClick={ onClose } className="btnToggleCloseActiveReview"><span className="material-symbols-rounded">close_small</span></button></div>
                </div>
                <div className="reviewActive_content">
                    <div className="reviewActive_ImageName">
                        <img src={ require( `../../../images/${ userImage }` ) } alt={ `Image ${nameUserRu}` } className="reviewActive_image" />
                        <div className="reviewActive_name">{ nameUserRu }</div>
                    </div>
                    <div className="reviewActive_message reviewActive_message_ru displayNone">{ messageUserRu }</div>
                </div>
            </div>
        </div>
    );
};

export default ReviewActive;