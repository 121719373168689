import React from "react";
import './ResultExperience.css';

const ResultExperience = () => {
    return (
        <div className="resultExperience-container section">
            <h1 className="resultExperience-title resultExperience_title_en">With the experience to back it up</h1>
            <h1 className="resultExperience-title resultExperience_title_ru displayNone">С опытом, который мог бы это подтвердить</h1>
            <div className="resultExperience-number">
                <div className="resultExperience-number_item">
                    <div className="resultExperience-number_item_num resultExperience_green">2+</div>
                    <div className="resultExperience-number_item_text number_item_text_green resultExperience_number_item_text1_en">Years of Experience</div>
                    <div className="resultExperience-number_item_text number_item_text_green resultExperience_number_item_text1_ru displayNone">Лет опыта работы</div>
                </div>
                <div className="resultExperience-number_item">
                    <div className="resultExperience-number_item_num resultExperience_yellow">10+</div>
                    <div className="resultExperience-number_item_text number_item_text_yellow resultExperience_number_item_text2_en">Websites</div>
                    <div className="resultExperience-number_item_text number_item_text_yellow resultExperience_number_item_text2_ru displayNone">Веб-сайтов</div>
                </div>
                <div className="resultExperience-number_item">
                    <div className="resultExperience-number_item_num resultExperience_pink">5+</div>
                    <div className="resultExperience-number_item_text number_item_text_pink resultExperience_number_item_text3_en">Web apps</div>
                    <div className="resultExperience-number_item_text number_item_text_pink resultExperience_number_item_text3_ru displayNone">Веб-приложений</div>
                </div>
            </div>
        </div>
    );
};

export default ResultExperience;