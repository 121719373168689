import React, { useRef, useState } from "react";
import './ContactForm.css';
import emailjs from '@emailjs/browser';

const ContactForm = () => {

    const form = useRef();

    const [showMessage, setShowMessage] = useState(false);

    const [imageMessage, setImageMessage] = useState('');
    const [nameMessage, setNameMessage] = useState('');
    const [textMessage, setTextMessage] = useState('');
    const [textButtonMessage, setTextButtonMessage] = useState('');

    const userNameRef = useRef( null );
    const userEmailRef = useRef( null );
    const userCategoryRef = useRef( null );
    const userMessageRef = useRef( null );

    const [nameErrorInput, setNameErrorInput] = useState('');
    const [emailErrorInput, setEmailErrorInput] = useState('');
    const [categoryErrorInput, setCategoryErrorInput] = useState('');
    const [messageErrorInput, setMessageErrorInput] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (checkFields()) {
            sendEmail(e);
        } 
        else {
            toggleButtonSendMessage();
        }
    };

    const checkFields = () => {
        let isValid = true;
        setImageMessage('error.png');
        setNameMessage('Error');
        setTextMessage('Your application has been successfully submitted. We will contact you soon!');
        setTextButtonMessage("I'll try again");
        document.querySelector('.messageAboutSend_resultMessage').style.color = '#FF4B4B';
    
        if (userNameRef.current.value === '') {
            setNameErrorInput('Name');
            isValid = false;
        } else {
            setNameErrorInput('');
        }
        
        if (userEmailRef.current.value === '') {
            setEmailErrorInput('Email');
            isValid = false;
        } else {
            setEmailErrorInput('');
        }
        
        if (userCategoryRef.current.value === '') {
            setCategoryErrorInput('Category');
            isValid = false;
        } else {
            setCategoryErrorInput('');
        }
        
        if (userMessageRef.current.value === '') {
            setMessageErrorInput('Message');
            isValid = false;
        } else {
            setMessageErrorInput('');
        }
        return isValid;   
    };

    const sendEmail = (e) => {
        e.preventDefault();
        emailjs.sendForm('service_2y8j4k6', 'template_jbcxj3e', form.current, 'cyvJmErg_utqVBQ7W')
        .then((result) => {
            console.log(result.text);
            document.querySelector('.messageAboutSend_resultMessage').style.color = '#A6FF98';
            e.target.reset();
            setImageMessage('success.png');
            setNameMessage('Thank you for contacting us');
            setTextMessage('Your application has been successfully submitted. We will contact you soon!');
            setTextButtonMessage('Great');
            setShowMessage(true); // обновляем состояние showMessage до true
        }, (error) => {
            console.log(error.text);
            setImageMessage('error.png');
            setNameMessage('Error');
            setTextMessage('Your application has been successfully submitted. We will contact you soon!');
            setTextButtonMessage("I'll try again");
            setShowMessage(true); // обновляем состояние showMessage до true
        })
    }
    const toggleButtonSendMessage = () => {
        document.querySelector('.messageAboutSendBlock').classList.toggle('notMessage');
    }
    

    return (
        <>
            <form ref={ form } onSubmit={ handleSubmit }>
                <div className="form_title">const <span className="colorForm">form</span> = &#123;</div>

                <div className="form_item form_item1_en">
                    <div className="form_item_title1">name:</div>
                    <div className="marksOne1">"</div>
                    <div className="form_item_input1">
                        <input type="text" placeholder="Enter your name" name="userName" ref={ userNameRef } />
                    </div>
                    <div className="marksOne2">"</div>
                </div>
                <div className="form_item form_item1_ru displayNone">
                    <div className="form_item_title1">имя:</div>
                    <div className="marksOne1">"</div>
                    <div className="form_item_input1">
                        <input type="text" placeholder="Введите Ваше имя" name="userName" ref={ userNameRef } />
                    </div>
                    <div className="marksOne2">"</div>
                </div>

                <div className="form_item form_item2_en">
                    <div className="form_item_title2">email:</div>
                    <div className="marksTwo1">"</div>
                    <div className="form_item_input2">
                        <input type="text" placeholder="Enter your email" name="userEmail" ref={ userEmailRef } />
                    </div>
                    <div className="marksTwo2">"</div>
                </div>
                <div className="form_item form_item2_ru displayNone">
                    <div className="form_item_title2">почта:</div>
                    <div className="marksTwo1">"</div>
                    <div className="form_item_input2">
                        <input type="text" placeholder="Введите Вашу почту" name="userEmail" ref={ userEmailRef } />
                    </div>
                    <div className="marksTwo2">"</div>
                </div>

                <div className="form_item form_item3_en">
                    <div className="form_item_title3">category:</div>
                    <div className="marksThree1">"</div>
                    <div className="form_item_input3">
                        <select name="selectCategory" ref={ userCategoryRef }>
                            <option value="" hidden disabled selected>Not selected</option>
                            <option value="Консультация">Consultation</option>
                            <option value="Разработка дизайн-макета">Development of a design layout</option>
                            <option value="Разработка веб-сайта">Website development</option>
                            <option value="Доработка веб-сайта">Website improvement</option>
                            <option value="Разработка логотипа/концепции">Concept/logo development</option>
                            <option value="Моделирование объектов">Object modeling</option>
                            <option value="Визуализация интерьера">Interior visualization</option>
                            <option value="Другое">Other</option>
                        </select>
                    </div>
                    <div className="marksThree2">"</div>
                </div>
                <div className="form_item form_item3_ru displayNone">
                    <div className="form_item_title3">категория:</div>
                    <div className="marksThree1">"</div>
                    <div className="form_item_input3">
                        <select name="selectCategory" ref={ userCategoryRef }>
                            <option value="" hidden disabled selected>Не выбрано</option>
                            <option value="Консультация">Консультация</option>
                            <option value="Разработка дизайн-макета">Разработка дизайн-макета</option>
                            <option value="Разработка веб-сайта">Разработка веб-сайта</option>
                            <option value="Доработка веб-сайта">Доработка веб-сайта</option>
                            <option value="Разработка логотипа/концепции">Разработка логотипа/концепции</option>
                            <option value="Моделирование объектов">Моделирование объектов</option>
                            <option value="Визуализация интерьера">Визуализация интерьера</option>
                            <option value="Другое">Другое</option>
                        </select>
                    </div>
                    <div className="marksThree2">"</div>
                </div>

                <div className="form_item form_item4_en">
                    <div className="form_item_title4">message:</div>
                    <div className="marksFour1">"</div>
                    <div className="form_item_input4">
                        <input type="text" placeholder="Enter your message" name="userMessage" ref={ userMessageRef } />
                    </div>
                    <div className="marksFour2">"</div>
                </div>
                <div className="form_item form_item4_ru displayNone">
                    <div className="form_item_title4">сообщение:</div>
                    <div className="marksFour1">"</div>
                    <div className="form_item_input4">
                        <input type="text" placeholder="Введите Ваше сообщение" name="userMessage" ref={ userMessageRef } />
                    </div>
                    <div className="marksFour2">"</div>
                </div>

                <div className="form_title">&#125;</div>
                <div className="form_button">
                    <button type="submit" className="form_button_btn">
                        sendEmail
                        <span className="form_button_btn-bracket">(</span>
                        <span className="form_button_btn-form">form</span>
                        <span className="form_button_btn-bracket">)</span>
                        <div className="form_button_btn_bg"></div>
                    </button>
                </div>
            </form>

            <div className={`messageAboutSendBlock ${showMessage? '' : 'notMessage'}`}>
                <div className="messageAboutSend">
                    <div className="messageAboutSend_image">
                        <img src={ imageMessage? require(`../../../images/${imageMessage}`) : '' } alt="" />
                    </div>
                    <div className="messageAboutSend_resultMessage">{ nameMessage }</div>
                    <div className="messageAboutSend_textMessage">{ textMessage }</div>
                    <div className="messageAboutSend_textMessageError">
                        { `${ nameErrorInput } ` }
                        { `${ emailErrorInput } ` }
                        { `${ categoryErrorInput } ` }
                        { messageErrorInput }
                    </div>
                    <div className="messageAboutSend_buttonMessage">
                        <button onClick={ toggleButtonSendMessage }>
                            <span className="linkSpanOne">{ textButtonMessage }</span>
                            <div className="messageAboutSend_buttonMessageGrad"></div>
                            <div className="messageAboutSend_buttonMessageBG"></div>
                        </button>
                    </div>
                </div>
            </div>
            
        </>
    );
};

export default ContactForm;