import React from "react";
import './Link.css';

const Link = () => {
    return (
        <div className="link_container section">
            <a className="linkOne" href="https://github.com/devoneoff" target="_blank"><span className="linkSpanOne">GitHub</span><div></div></a>
            <a className="linkTwo" href="https://www.behance.net/devoneoff" target="_blank"><span className="linkSpanTwo">Behance</span><div></div></a>
            <a className="linkThree" href="https://dribbble.com/devoneoff" target="_blank"><span className="linkSpanThree">Dribbble</span><div></div></a>
            <a className="linkFour disable" href=""><span className="linkSpanFour">Pinterest</span><div></div></a>
            <a className="linkFive" download href={ require( '../../docs/CV DevoneOff.pdf' ) } target="_blank"><span className="linkSpanFive">CV</span><div></div></a>
        </div>
    );
};

export default Link;