import React from 'react';
import './CaseProjectCard.css';

const CaseProjectCard = ( { cases, onOpenModal  } ) => {

    return (
        <div className="caseProjectCard_container">
            { cases.map( ( item, index ) => (
                <React.Fragment key={`case_${ index}`}>
                    <div className="caseProjectCard-card">
                        <img src={require(`../../../images/projects/${ item.productionImage }`)} alt="" />
                        <div className="caseProjectCard_content">
                            <div className="caseProjectCard_content-title">{ item.caseName }</div>
                            <div className="caseProjectCard_content-stacks">
                                { item.stacks.map( (caseStack, caseStackIndex) => (
                                    <div
                                        className="caseProjectCard-stack" 
                                        key={ `caseStack_${ caseStackIndex }` }
                                    >
                                        <span>{ caseStack.stack }</span>
                                    </div>
                                ) )}
                            </div>
                        </div>
                        <div className="caseProjectCard-card-Link">
                            <div className="caseProjectCard-Link-icon">
                                <button
                                    className='caseProjectCard-Link-icon-btn'
                                    onClick={ () => onOpenModal( item ) }
                                >
                                    <img src={require('../../../images/link.png')} alt="" />
                                </button>
                            </div>
                        </div>
                    </div>
                </React.Fragment>
            ))}            
        </div>
    );
};

export default CaseProjectCard;