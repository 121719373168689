import React from "react";
import './About.css';

const About = () => {
    return (
        <>
        <div className="about_container section" id="about">
            <h1 className="aboutTitle_en">About <span className="gradientTextTitle">Myself</span></h1>
            <h1 className="aboutTitle_ru displayNone">О <span className="gradientTextTitle">Себе</span></h1>
            <p className="aboutSubtitle_en">HTML, CSS, JavaScript and others. I have experience working with CMS, but I prefer self-written solutions because of the possibility of creating an absolutely unique interface that embodies all the ideas and ideas on the page.</p>
            <p className="aboutSubtitle_ru displayNone">HTML, CSS, JavaScript и другие. У меня есть опыт работы с CMS, однако я предпочитаю самописные решения из-за возможности создания абсолютно уникального интерфейса, воплощающего все идеи и задумки на странице.</p>
            <div className="about_content">
                <div className="about_card appearAboutCard">
                    <img src="./assets/images/hand.png" className="hand" alt="" />
                    <p className="hello hello_en"><span>Hello, I am</span></p>
                    <p className="hello hello_ru displayNone"><span>Привет, Я</span></p>
                    <p className="my_name my_name_en">Irina Krylova</p>
                    <p className="my_name my_name_ru displayNone">Ирина Крылова</p>
                    <div className="about_image">
                        <img src={ require( '../../images/devoneoff.png' ) } alt="" />
                    </div>
                </div>
                <div className="about_table appearAboutTable">
                    <div className="about_table_item">
                        <h2 className="about_table_title1 about_table_title1_en">Skills:</h2>
                        <h2 className="about_table_title1 about_table_title1_ru displayNone">Навыки:</h2>
                        <p className="about_table_text1_en">Adaptive, cross-browser and interactive layout using modern technologies. You can find out more about my skills and their proficiency levels in the "Stack" section</p>
                        <p className="about_table_text1_ru displayNone">Адаптивная, кроссбраузерная и интерактивная верстка с использованием современных технологий. Подробнее о моих навыках и уровне владения ими вы можете узнать в разделе "Стек".</p>
                    </div>
                    <div className="about_table_item">
                        <h2 className="about_table_title2 about_table_title2_en">My Journey In Code:</h2>
                        <h2 className="about_table_title2 about_table_title2_ru displayNone">Мое Путешествие В Коде:</h2>
                        <p className="about_table_text2_en">Learning various development tools and languages. The ability to read someone else's code</p>
                        <p className="about_table_text2_ru displayNone">Изучение различных инструментов и языков разработки. Умение читать чужой код</p>
                    </div>
                    <div className="about_table_item">
                        <h2 className="about_table_title3 about_table_title3_en">Digital Experiences:</h2>
                        <h2 className="about_table_title3 about_table_title3_ru displayNone">Цифровой опыт:</h2>
                        <p className="about_table_text3_en">I have little commercial experience in web development. I did an internship at JV Business Car LLC as a "Web Developer Intern".</p>
                        <p className="about_table_text3_ru displayNone">У меня небольшой коммерческий опыт в веб-разработке. Я проходила стажировку в ООО "СП Бизнес Кар" в качестве "Стажёра веб-разработчика"</p>
                    </div>
                    <div className="about_table_item">
                        <h2 className="about_table_title4 about_table_title4_en">Hobbies:</h2>
                        <h2 className="about_table_title4 about_table_title4_ru displayNone">Увлечения:</h2>
                        <p className="about_table_text4_en">Undoubtedly, the world of development has become for me not only a source of education, but also a hobby. However, in addition to this, I also enjoy a number of other hobbies: photography and videography, graphic design, skateboarding and cycling, music, movies and TV series, computer and mobile games.</p>
                        <p className="about_table_text4_ru displayNone">Несомненно, мир разработки стал для меня не только источником образования, но и увлечением. Однако, помимо этого, я также увлекаюсь рядом других увлечений: фотографией и видеосъемкой, графическим дизайном, скейтбордингом и ездой на велосипеде, музыкой, фильмами и сериалами, компьютерными и мобильными играми.</p>
                    </div>
                </div>
            </div>
        </div>
        </>
    );
};

export default About;