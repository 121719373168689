import React from "react";
import './StageDevCard.css';
import '../../Cursor/Cursor.css';

const StageDevCard = ( { title, titleRu, number, image, about, aboutRu } ) => {
    return (
        <div className="stagesDevCard-container">
            <div className={ `stagesDevCard-front stagesDevCard-front${ number }` }>
                <div className="stagesDevCard-front_number">{ number }</div>
                <div className="stagesDevCard-front_content">
                    <div className="stagesDevCard-front_content-image">
                        <img src={ require( `../../../images/${image}` ) } alt="" />
                    </div>
                    <div className={`stagesDevCard-front_content-title stagesDevCard_front_content_title${number}_en`}>{ title }</div>
                    <div className={`stagesDevCard-front_content-title stagesDevCard_front_content_title${number}_ru displayNone`}>{ titleRu }</div>
                </div>
            </div>
            <div className="stagesDevCard-back">
                <h3 className={ `stagesDevCard_backH3_${number}_en` }>{ title }</h3>
                <h3 className={ `stagesDevCard_backH3_${number}_ru displayNone` }>{ titleRu }</h3>

                <p className={ `stagesDevCard_backP_${number}_en` }>{ about }</p>
                <p className={ `stagesDevCard_backP_${number}_ru displayNone` }>{ aboutRu }</p>
            </div>
        </div>
    );
};

export default StageDevCard;