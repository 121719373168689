import React from "react";
import './ContactSocialLink.css';

const ContactSocialLink = ( { numSocial, title, image, imageWhite, imageBlack, link } ) => {
    return (
        <>
            <div className={` contact_social_content_item${ numSocial } `}>
                <a href={ link } className="contact_social_content_item_link" target="_blank">
                    <img src={ require( `../../../images/social/${ image }` ) } alt={ title }  className="socialOriginal" />
                    <img src={ require( `../../../images/social/${ imageWhite }` ) } alt={ `${ title }_light` } className="socialLight" />
                    <img src={ require( `../../../images/social/${ imageBlack }` ) } alt={ `${ title }_dark` } className="socialDark" />
                </a>
            </div>
        </>
    );
};

export default ContactSocialLink;