import React from "react";
import './ReviewCard.css';

const ReviewCard = ( { details, onClick } ) => {

    const truncate = ( text, length ) => {
        if ( text.length > length ) {
            return text.substring( 0, length ) + '...';
        }
        return text;
    }
    return (
        <>
            <div className={ `reviewCard_container${ details.idUser }` }>
                <img 
                    src={ require( `../../../images/${ details.imageUser }` ) } 
                    alt={`imageUser_${ details.nameUser }`} 
                    className="reviewCard_image"
                />
                <div className="reviewCard_text">
                    <div className="reviewCardNameAndButton">
                        <div className="reviewCard_name">{ details.nameUser }</div>
                        <div className="reviewCardButton"><button onClick={ () => onClick( details ) }>More</button></div>
                    </div>
                    <div className="reviewCard_message">{ truncate(details.messageUser, 120) }</div>
                </div>
            </div>
        </>
    );
};

export default ReviewCard;