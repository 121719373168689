import React, { useState } from "react";
import './ButtonLanguage.css';

const ButtonLanguage = () => {

    const langs = [ 'en', 'ru' ];
    const [ lang, setLang ] = useState( langs[0] );

    const toggleLanguages = () => {
        document.querySelector('.langs').classList.toggle('ru');
        const currentIndex = langs.indexOf( lang );
        const nextIndex = ( currentIndex + 1 ) % langs.length;
        setLang( langs[ nextIndex ] );

        // navbar
        document.querySelector('.ul_en').classList.toggle('displayNone');
        document.querySelector('.ul_ru').classList.toggle('displayNone');
        document.querySelector('.mobile_ul_en').classList.toggle('displayNone');
        document.querySelector('.mobile_ul_ru').classList.toggle('displayNone');
        document.querySelector('.menu_itemOne_en').classList.toggle('displayNone');
        document.querySelector('.menu_itemOne_ru').classList.toggle('displayNone');
        document.querySelector('.menu_itemTwo_en').classList.toggle('displayNone');
        document.querySelector('.menu_itemTwo_ru').classList.toggle('displayNone');
        document.querySelector('.menu_itemThree_en').classList.toggle('displayNone');
        document.querySelector('.menu_itemThree_ru').classList.toggle('displayNone');
        document.querySelector('.menu_itemFour_en').classList.toggle('displayNone');
        document.querySelector('.menu_itemFour_ru').classList.toggle('displayNone');
        document.querySelector('.menu_itemFive_en').classList.toggle('displayNone');
        document.querySelector('.menu_itemFive_ru').classList.toggle('displayNone');
        // home
        document.querySelector('.home_content_title_en').classList.toggle('displayNone');
        document.querySelector('.home_content_title_ru').classList.toggle('displayNone');
        // about
        document.querySelector('.aboutTitle_en').classList.toggle('displayNone');
        document.querySelector('.aboutTitle_ru').classList.toggle('displayNone');
        document.querySelector('.aboutSubtitle_en').classList.toggle('displayNone');
        document.querySelector('.aboutSubtitle_ru').classList.toggle('displayNone');
        document.querySelector('.hello_en').classList.toggle('displayNone');
        document.querySelector('.hello_ru').classList.toggle('displayNone');
        document.querySelector('.my_name_en').classList.toggle('displayNone');
        document.querySelector('.my_name_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_title1_en').classList.toggle('displayNone');
        document.querySelector('.about_table_title1_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_text1_en').classList.toggle('displayNone');
        document.querySelector('.about_table_text1_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_title2_en').classList.toggle('displayNone');
        document.querySelector('.about_table_title2_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_text2_en').classList.toggle('displayNone');
        document.querySelector('.about_table_text2_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_title3_en').classList.toggle('displayNone');
        document.querySelector('.about_table_title3_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_text3_en').classList.toggle('displayNone');
        document.querySelector('.about_table_text3_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_title4_en').classList.toggle('displayNone');
        document.querySelector('.about_table_title4_ru').classList.toggle('displayNone');
        document.querySelector('.about_table_text4_en').classList.toggle('displayNone');
        document.querySelector('.about_table_text4_ru').classList.toggle('displayNone');
        // technologies
        document.querySelector('.technologies_text_en').classList.toggle('displayNone');
        document.querySelector('.technologies_text_ru').classList.toggle('displayNone');
        // result experience
        document.querySelector('.resultExperience_title_en').classList.toggle('displayNone');
        document.querySelector('.resultExperience_title_ru').classList.toggle('displayNone');
        document.querySelector('.resultExperience_number_item_text1_en').classList.toggle('displayNone');
        document.querySelector('.resultExperience_number_item_text1_ru').classList.toggle('displayNone');
        document.querySelector('.resultExperience_number_item_text2_en').classList.toggle('displayNone');
        document.querySelector('.resultExperience_number_item_text2_ru').classList.toggle('displayNone');
        document.querySelector('.resultExperience_number_item_text3_en').classList.toggle('displayNone');
        document.querySelector('.resultExperience_number_item_text3_ru').classList.toggle('displayNone');
        // stages development
        document.querySelector('.stagesDev_title_itemOne_en').classList.toggle('displayNone');
        document.querySelector('.stagesDev_title_itemOne_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDev_title_itemTwo_en').classList.toggle('displayNone');
        document.querySelector('.stagesDev_title_itemTwo_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDev_title_itemThree_en').classList.toggle('displayNone');
        document.querySelector('.stagesDev_title_itemThree_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title1_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title1_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title2_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title2_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title3_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title3_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title4_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_front_content_title4_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_1_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_1_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_2_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_2_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_3_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_3_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_4_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backH3_4_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_1_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_1_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_2_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_2_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_3_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_3_ru').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_4_en').classList.toggle('displayNone');
        document.querySelector('.stagesDevCard_backP_4_ru').classList.toggle('displayNone');
        // cases
        document.querySelector('.case_title_en').classList.toggle('displayNone');
        document.querySelector('.case_title_ru').classList.toggle('displayNone');
        document.querySelector('.case_text_en').classList.toggle('displayNone');
        document.querySelector('.case_text_ru').classList.toggle('displayNone');
        // stages implementation
        document.querySelector('.stagesOfImplementation_title_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_title_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title1_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title1_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text1_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text1_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title2_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title2_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text2_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text2_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title3_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title3_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text3_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text3_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title4_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_title4_ru').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text4_en').classList.toggle('displayNone');
        document.querySelector('.stagesOfImplementation_item_info_content_text4_ru').classList.toggle('displayNone');
        document.querySelector('.attention_text_en').classList.toggle('displayNone');
        document.querySelector('.attention_text_ru').classList.toggle('displayNone');
        // reviews
        document.querySelector('.reviews_title_en').classList.toggle('displayNone');
        document.querySelector('.reviews_title_ru').classList.toggle('displayNone');
        document.querySelector('.reviews_content').classList.toggle('displayNone');
        document.querySelector('.reviews_contentRu').classList.toggle('displayNone');
        document.querySelector('.actReviewEn').classList.toggle('displayNone');
        document.querySelector('.actReviewRu').classList.toggle('displayNone');
        // contact
        document.querySelector('.contact_content_p1_en').classList.toggle('displayNone');
        document.querySelector('.contact_content_p1_ru').classList.toggle('displayNone');
        document.querySelector('.contact_content_p2_en').classList.toggle('displayNone');
        document.querySelector('.contact_content_p2_ru').classList.toggle('displayNone');
        document.querySelector('.contact_content_info_p_en').classList.toggle('displayNone');
        document.querySelector('.contact_content_info_p_ru').classList.toggle('displayNone');
        document.querySelector('.contact_form_title_en').classList.toggle('displayNone');
        document.querySelector('.contact_form_title_ru').classList.toggle('displayNone');

        document.querySelector('.form_item1_en').classList.toggle('displayNone');
        document.querySelector('.form_item1_ru').classList.toggle('displayNone');
        document.querySelector('.form_item2_en').classList.toggle('displayNone');
        document.querySelector('.form_item2_ru').classList.toggle('displayNone');
        document.querySelector('.form_item3_en').classList.toggle('displayNone');
        document.querySelector('.form_item3_ru').classList.toggle('displayNone');
        document.querySelector('.form_item4_en').classList.toggle('displayNone');
        document.querySelector('.form_item4_ru').classList.toggle('displayNone');
        // footer
        document.querySelector('.policy_en').classList.toggle('displayNone');
        document.querySelector('.policy_ru').classList.toggle('displayNone');
        document.querySelector('.up_en').classList.toggle('displayNone');
        document.querySelector('.up_ru').classList.toggle('displayNone');
        document.querySelector('.mobile_up_en').classList.toggle('displayNone');
        document.querySelector('.mobile_up_ru').classList.toggle('displayNone');
        document.querySelector('.mobile_policy_en').classList.toggle('displayNone');
        document.querySelector('.mobile_policy_ru').classList.toggle('displayNone');


    }

    return (
        <button className="buttonLang_container" onClick={ toggleLanguages }>
            <div className="langs">
                <div className="lang">{ lang }</div>
            </div>
        </button>
    );
};

export default ButtonLanguage;