import React from "react";
import './Glitch.css';

const Glitch = () => {
    return (
        <div className="glitch-container section">
            <div className="glitch-wrapper">
                <div className="glitch" data-glitch="DEVONE.OFF">DEVONE.OFF</div>
            </div>
        </div>
    );
};

export default Glitch;