import React from "react";
import './ButtonTheme.css';
import { useState } from "react";

const ButtonTheme = () => {
    const [ changeTheme, setChangeTheme ] = useState( false );
    const toggleTheme = () => {
        setChangeTheme( !changeTheme );
        // styles theme button
        document.querySelector('.buttonToggleTheme').classList.toggle("light");
        document.querySelector('.themeButton').classList.toggle("light");
        document.querySelector('.circle1').classList.toggle("light");
        document.querySelector('.circle2').classList.toggle("light");
        document.querySelector('.circle3').classList.toggle("light");
        document.querySelector('.toggleTheme').classList.toggle("light");
        document.querySelector('.inRound').classList.toggle("light");
        document.querySelector('.sky').classList.toggle("light");
        document.querySelector('.sky_und').classList.toggle("light");
        document.querySelector('.starButton1').classList.toggle("light");
        document.querySelector('.starButton2').classList.toggle("light");
        document.querySelector('.starButton3').classList.toggle("light");
        document.querySelector('.starButton4').classList.toggle("light");
        document.querySelector('.starButton5').classList.toggle("light");
        document.querySelector('.starButton6').classList.toggle("light");
        document.querySelector('.menu_itemOne_ru').classList.toggle("light");
        document.querySelector('.menu_itemTwo_ru').classList.toggle("light");
        document.querySelector('.menu_itemThree_ru').classList.toggle("light");
        document.querySelector('.menu_itemFour_ru').classList.toggle("light");
        document.querySelector('.menu_itemFive_ru').classList.toggle("light");
        document.querySelector('.buttonLang_container').classList.toggle("light");
        document.querySelector('.languagetheme').classList.toggle("light");
        // styles page Mobile Nav
        document.querySelector('.menu_itemOne').classList.toggle("light");
        document.querySelector('.menu_itemTwo').classList.toggle("light");
        document.querySelector('.menu_itemThree').classList.toggle("light");
        document.querySelector('.menu_itemFour').classList.toggle("light");
        document.querySelector('.menu_itemFive').classList.toggle("light");
        document.querySelector('.mobile_menu_itemOne_ru').classList.toggle("light");
        document.querySelector('.mobile_menu_itemTwo_ru').classList.toggle("light");
        document.querySelector('.mobile_menu_itemThree_ru').classList.toggle("light");
        document.querySelector('.mobile_menu_itemFour_ru').classList.toggle("light");
        document.querySelector('.mobile_menu_itemFive_ru').classList.toggle("light");
        document.querySelector('.mobile_menu_itemOne_en').classList.toggle("light");
        document.querySelector('.mobile_menu_itemTwo_en').classList.toggle("light");
        document.querySelector('.mobile_menu_itemThree_en').classList.toggle("light");
        document.querySelector('.mobile_menu_itemFour_en').classList.toggle("light");
        document.querySelector('.mobile_menu_itemFive_en').classList.toggle("light");
        // styles page Home
        document.querySelector('body').classList.toggle("light");
        document.querySelector('.nav_logo_text').classList.toggle("light");
        document.querySelector('#menu_itemOne').classList.toggle("light");
        document.querySelector('#menu_itemTwo').classList.toggle("light");
        document.querySelector('#menu_itemThree').classList.toggle("light");
        document.querySelector('#menu_itemFour').classList.toggle("light");
        document.querySelector('#menu_itemFive').classList.toggle("light");
        document.querySelector('.menu-btn').classList.toggle("light");
        document.querySelector('.home_content_title').classList.toggle("light");
        document.querySelector('.line1').classList.toggle("light");
        document.querySelector('.line2').classList.toggle("light");
        document.querySelector('.mouse').classList.toggle("light");
        document.querySelector('.bg-home').classList.toggle("light");
        document.querySelector('.home_content_title_ru').classList.toggle("light");
        // styles page About
        document.querySelector('.about_container').classList.toggle("light");
        document.querySelector('.about_card').classList.toggle("light");
        document.querySelector('.my_name').classList.toggle("light");
        document.querySelector('.my_name_ru').classList.toggle("light");
        document.querySelector('.about_table_title1').classList.toggle("light");
        document.querySelector('.about_table_title2').classList.toggle("light");
        document.querySelector('.about_table_title3').classList.toggle("light");
        document.querySelector('.about_table_title4').classList.toggle("light");
        document.querySelector('.about_table_title1_ru').classList.toggle("light");
        document.querySelector('.about_table_title2_ru').classList.toggle("light");
        document.querySelector('.about_table_title3_ru').classList.toggle("light");
        document.querySelector('.about_table_title4_ru').classList.toggle("light");
        // styles page Technologies
        document.querySelector('.technologies_text').classList.toggle("light");
        // styles page Result Experiences
        document.querySelector('.resultExperience-title').classList.toggle("light");
        document.querySelector('.resultExperience_title_ru').classList.toggle("light");
        document.querySelector('.number_item_text_green').classList.toggle("light");
        document.querySelector('.number_item_text_yellow').classList.toggle("light");
        document.querySelector('.resultExperience_yellow').classList.toggle("light");
        document.querySelector('.number_item_text_pink').classList.toggle("light");
        document.querySelector('.resultExperience_number_item_text1_ru').classList.toggle("light");
        document.querySelector('.resultExperience_number_item_text2_ru').classList.toggle("light");
        document.querySelector('.resultExperience_number_item_text3_ru').classList.toggle("light");
        // styles page Stacks
        document.querySelector('.stackRound_title1').classList.toggle("light");
        document.querySelector('.stackRound_title2').classList.toggle("light");
        document.querySelector('.stackRound_title3').classList.toggle("light");
        document.querySelector('.stackRound_title4').classList.toggle("light");
        document.querySelector('.stackRound_title5').classList.toggle("light");
        document.querySelector('.stackRound_title6').classList.toggle("light");
        // styles page Stages
        document.querySelector('.stagesDev_title_itemOne').classList.toggle("light");
        document.querySelector('.stagesDev_title_itemTwo').classList.toggle("light");
        document.querySelector('.stagesDev_title_itemThree').classList.toggle("light");
        document.querySelector('.stages_line').classList.toggle("light");
        document.querySelector('.stages_lineTwo').classList.toggle("light");
        // styles page Glitch
        document.querySelector('.glitch').classList.toggle("light");
        // styles page Stages Development
        document.querySelector('.stagesDevCard-front1').classList.toggle("light");
        document.querySelector('.stagesDevCard-front2').classList.toggle("light");
        document.querySelector('.stagesDevCard-front3').classList.toggle("light");
        document.querySelector('.stagesDevCard-front4').classList.toggle("light");
        document.querySelector('.stagesDev_title_itemOne_ru').classList.toggle("light");
        document.querySelector('.stagesDev_title_itemTwo_ru').classList.toggle("light");
        document.querySelector('.stagesDev_title_itemThree_ru').classList.toggle("light");
        // styles page Case
        document.querySelector('.case_title').classList.toggle("light");
        document.querySelector('.case_title_ru').classList.toggle("light");
        document.querySelector('.case_text').classList.toggle("light");
        document.querySelector('.case_text_ru').classList.toggle("light");
        // styles page Stages Of Implementation
        document.querySelector('.stagesOfImplementation_title').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_title_ru').classList.toggle("light");

        document.querySelector('.stagesOfImplementation_item_info_content_title1_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title2_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title3_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title4_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text1_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text2_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text3_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text4_en').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title1_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title2_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title3_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_title4_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text1_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text2_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text3_ru').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_content_text4_ru').classList.toggle("light");

        document.querySelector('.stagesOfImplementation_item_info_lineOne.one').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineOne.two').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineOne.three').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineOne.four').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineTwo.one').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineTwo.two').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineTwo.three').classList.toggle("light");
        document.querySelector('.stagesOfImplementation_item_info_lineTwo.four').classList.toggle("light");
        document.querySelector('.attention_text').classList.toggle("light");
        document.querySelector('.attention_text_ru').classList.toggle("light");
        document.querySelector('.stagesImpLineOne1').classList.toggle("light");
        document.querySelector('.stagesImpLineOne2').classList.toggle("light");
        document.querySelector('.stagesImpLineOne3').classList.toggle("light");
        document.querySelector('.stagesImpLineOne4').classList.toggle("light");
        document.querySelector('.stagesImpLineOne5').classList.toggle("light");
        document.querySelector('.stagesImpLineTwo1').classList.toggle("light");
        document.querySelector('.stagesImpLineTwo2').classList.toggle("light");
        document.querySelector('.stagesImpLineTwo3').classList.toggle("light");
        document.querySelector('.stagesImpLineTwo4').classList.toggle("light");
        document.querySelector('.stagesImpLineTwo5').classList.toggle("light");
        document.querySelector('.stagesImpLineThree1').classList.toggle("light");
        document.querySelector('.stagesImpLineThree2').classList.toggle("light");
        document.querySelector('.stagesImpLineThree3').classList.toggle("light");
        document.querySelector('.stagesImpLineThree4').classList.toggle("light");
        document.querySelector('.stagesImpLineThree5').classList.toggle("light");
        document.querySelector('.stagesImpLineFour1').classList.toggle("light");
        document.querySelector('.stagesImpLineFour2').classList.toggle("light");
        document.querySelector('.stagesImpLineFour3').classList.toggle("light");
        document.querySelector('.stagesImpLineFour4').classList.toggle("light");
        document.querySelector('.stagesImpLineFour5').classList.toggle("light");
        // styles page Reviews
        document.querySelector('.reviews_title').classList.toggle("light");
        document.querySelector('.reviews_title_ru').classList.toggle("light");
        // styles page Code Screen
        document.querySelector('.codeScreen_container').classList.toggle("light");
        // styles page Links
        document.querySelector('.linkOne').classList.toggle("light");
        document.querySelector('.linkTwo').classList.toggle("light");
        document.querySelector('.linkThree').classList.toggle("light");
        document.querySelector('.linkFour').classList.toggle("light");
        document.querySelector('.linkFive').classList.toggle("light");
        document.querySelector('.linkSpanOne').classList.toggle("light");
        document.querySelector('.linkSpanTwo').classList.toggle("light");
        document.querySelector('.linkSpanThree').classList.toggle("light");
        document.querySelector('.linkSpanFour').classList.toggle("light");
        document.querySelector('.linkSpanFive').classList.toggle("light");
        // styles page Contact
        document.querySelector('.contact_social_content_item1').classList.toggle("light");
        document.querySelector('.contact_social_content_item2').classList.toggle("light");
        document.querySelector('.contact_social_content_item3').classList.toggle("light");
        document.querySelector('.contact_social_content_item4').classList.toggle("light");
        document.querySelector('.contact_social_content_item5').classList.toggle("light");
        document.querySelector('.contact_social_content_item6').classList.toggle("light");
        document.querySelector('.contact_social_content_item7').classList.toggle("light");
        document.querySelector('.contact_social_content_item8').classList.toggle("light");
        document.querySelector('.form_button_btn').classList.toggle("light");

    }
    
    return (
        <button className="buttonToggleTheme" onClick={ toggleTheme }>
            <div className="themeButton">
                <div className="circle1"></div>
                <div className="circle2"></div>
                <div className="circle3"></div>
                <div className="toggleTheme">
                    <div className="inRound"></div>
                </div>
                <div className="sky">
                    <div className="sky_round"></div>
                    <div className="sky_round"></div>
                    <div className="sky_round"></div>
                    <div className="sky_round"></div>
                    <div className="sky_round"></div>
                    <div className="sky_round"></div>
                </div>
                <div className="sky_und">
                    <div className="sky_und_round"></div>
                    <div className="sky_und_round"></div>
                    <div className="sky_und_round"></div>
                    <div className="sky_und_round"></div>
                    <div className="sky_und_round"></div>
                    <div className="sky_und_round"></div>
                </div>
                <div className="cosmosstar">
                    <img src={ require( '../../images/cosmostar.png' ) } alt="" className="starButton1" />
                    <img src={ require( '../../images/cosmostar.png' ) } alt="" className="starButton2" />
                    <img src={ require( '../../images/cosmostar.png' ) } alt="" className="starButton3" />
                    <img src={ require( '../../images/cosmostar.png' ) } alt="" className="starButton4" />
                    <img src={ require( '../../images/cosmostar.png' ) } alt="" className="starButton5" />
                    <img src={ require( '../../images/cosmostar.png' ) } alt="" className="starButton6" />
                </div>
            </div>
        </button>
    )
}

export default ButtonTheme;