import './App.css';
import About from './components/About/About';
import Case from './components/Case/Case';
import CodeScreen from './components/CodeScreen/CodeScreen';
import Contact from './components/Contact/Contact';
import Cursor from './components/Cursor/Cursor';
import Footer from './components/Footer/Footer';
import Glitch from './components/Glitch/Glitch';
import Home from './components/Home/Home';
import Link from './components/Link/Link';
import MobileFooter from './components/MobileFooter/MobileFooter';
import Navbar from './components/Navbar/Navbar';
import ResultExperience from './components/ResultExperience/ResultExperience';
import Reviews from './components/Reviews/Reviews';
import Stack from './components/Stack/Stack';
import StagesDevelopment from './components/StagesDevelopment/StagesDevelopment';
import StagesOfImplementation from './components/StagesOfImplementation/StagesOfImplementation';
import Technologies from './components/Technologies/Technologies';

const App = () => {
  return (
    <>
      <Cursor />
      <Navbar />
      <div className="container">
          <Home />
          <About />
          <Technologies />
          <ResultExperience />
          <Stack />
          <Glitch />
          <StagesDevelopment />
          <Case />
          <StagesOfImplementation />
          <Reviews />
          <CodeScreen />
          <Link />
          <Contact />
      </div>
      <Footer />
      <MobileFooter />
    </>
  );
}

export default App;
