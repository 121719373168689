import React, { useState } from 'react';
import './Case.css';
import CaseTitleCard from './CaseTitleCard/CaseTitleCard';
import { CASES } from '../../utils/data';
import CaseProjectCard from './CaseProjectCard/CaseProjectCard';
import CaseActive from './CaseActive/CaseActive';

const Case = () => {

    const [ selectedCase, setSelectedCase ] = useState( CASES[0] );
    const handleSelectCase = ( data ) => {
        setSelectedCase( data );
    }

    const [ isActive, setIsActive ] = useState( false );
    const [ activeCase, setActiveCase ] = useState( {} );
    const handleOpenModal = ( caseData ) => {
        setIsActive( true );
        setActiveCase( caseData );
        document.querySelector('body').classList.toggle('hidden');
    }
    const handleCloseModal = () => {
        setIsActive( false );
        document.querySelector('body').classList.toggle('hidden');
    }

    return (
        <div className="case_container section" id='case'>
            <h3 className="case_title case_title_en">Completed <span className='gradientTextTitle'>Projects</span></h3>
            <h3 className="case_title case_title_ru displayNone">Завершённые <span className='gradientTextTitle'>Проекты</span></h3>
            <div className="caseTitleCard_content">
                { CASES.map( ( item ) => (
                    <CaseTitleCard 
                        key={ item.title }
                        title={ item.title }
                        numCategory={ item.numCategory }
                        isActive={ selectedCase.title === item.title }
                        onClick={ () => {
                            handleSelectCase( item );
                        }}
                    />
                    ))}
                <p className="case_text case_text_en">{ selectedCase.aboutCategory } </p>
                <p className="case_text case_text_ru displayNone">{ selectedCase.aboutCategoryRu } </p>
            </div>
            <div className="case-all-container">
                <CaseProjectCard
                    title={ selectedCase.caseName }
                    cases={ selectedCase.cases }
                    onOpenModal={ handleOpenModal }
                />
            </div>
            
            { isActive && (
                <CaseActive
                    caseData={ activeCase }
                    onClose={ handleCloseModal }
                />
            )}
        </div>
    );
};

export default Case;