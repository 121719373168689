import React from "react";
import './MobileNav.css';

const MobileNav = ({ isOpen, toggleMenu}) => {

    return (
        <>
            <div
                className={ `mobile-menu ${ isOpen ? "active" : "" }` }
                onClick={ toggleMenu }
            >
                <div className="mobile-menu-container">
                    <ul className="mobile_ul_en">
                        <li><a href="#home" className="menu_itemOne mobile_menu_itemOne_en" id="menu_itemOne">Home</a></li>
                        <li><a href="#about" className="menu_itemTwo mobile_menu_itemTwo_en" id="menu_itemTwo">About</a></li>
                        <li><a href="#stack" className="menu_itemThree mobile_menu_itemThree_en" id="menu_itemThree">Stack</a></li>
                        <li><a href="#case" className="menu_itemFour mobile_menu_itemFour_en" id="menu_itemFour">Case</a></li>
                        <li><a href="#contact" className="menu_itemFive mobile_menu_itemFive_en" id="menu_itemFive">Contact</a></li>
                    </ul>
                    <ul className="mobile_ul_ru displayNone">
                        <li><a href="#home" className="menu_itemOne mobile_menu_itemOne_ru displayNone" id="menu_itemOne">Главная</a></li>
                        <li><a href="#about" className="menu_itemTwo mobile_menu_itemTwo_ru displayNone" id="menu_itemTwo">О Себе</a></li>
                        <li><a href="#stack" className="menu_itemThree mobile_menu_itemThree_ru displayNone" id="menu_itemThree">Стек</a></li>
                        <li><a href="#case" className="menu_itemFour mobile_menu_itemFour_ru displayNone" id="menu_itemFour">Кейс</a></li>
                        <li><a href="#contact" className="menu_itemFive mobile_menu_itemFive_ru displayNone" id="menu_itemFive">Контакты</a></li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default MobileNav;