import React, { useRef, useState } from "react";
import './Reviews.css';
import Slider from "react-slick";
import { REVIEWS } from "../../utils/data";
import ReviewCard from "./ReviewCard/ReviewCard";
import ReviewCardRu from "./ReviewCard/ReviewCardRu";
import ReviewActive from "./ReviewActive/ReviewActive";
import ReviewActiveRu from "./ReviewActive/ReviewActiveRu";

const Reviews = () => {

    const sliderRef = useRef();

    const settings = {
        dots: false,
        infinity: true,
        speed: 2000,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        // pauseOnHover: true,
        // autoplay: true,
        // autoplaySpeed: 2000,
        cssEase: 'linear',
        centerPadding: '100px',
        responsive: [
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 2,
                },
            },
            {
                breakpoint: 520,
                settings: {
                    slidesToShow: 1,
                },
            },
        ],
    };

    const [ activeReview, setActiveReview ] = useState( null );
    const handleOpenReview = ( review ) => {
        setActiveReview( review );
    };
    const handleCloseReview = () => {
        setActiveReview( null );
    }

    return (
        <>
            <div className="reviews_container section">
                <div className="reviews_title reviews_title_en">Reviews from people<span className="gradientTextTitle"> who trust me</span></div>
                <div className="reviews_title reviews_title_ru displayNone">Отзывы людей,<span className="gradientTextTitle"> которые мне доверяют</span></div>
                <div className="reviews_content">
                    <Slider key={ sliderRef } { ...settings }>
                        { REVIEWS.map( ( item ) => (
                            <ReviewCard key={ item.idUser } details={ item } onClick={ handleOpenReview } />
                        ) ) }
                    </Slider>
                </div>
                <div className="reviews_contentRu displayNone">
                    <Slider key={ sliderRef } { ...settings }>
                        { REVIEWS.map( ( item ) => (
                            <ReviewCardRu key={ item.idUser } details={ item } onClick={ handleOpenReview } />
                        ) ) }
                    </Slider>
                </div>
                <div className="actReviewEn">
                    { activeReview && (
                        <ReviewActive
                            idUser={ activeReview.idUser }
                            userImage={ activeReview.imageUser }
                            userName={ activeReview.nameUser }
                            userMessage={ activeReview.messageUser }
                            onClose={ handleCloseReview }
                        />
                    )}
                </div>
                <div className="actReviewRu displayNone">
                    { activeReview && (
                        <ReviewActiveRu
                            idUser={ activeReview.idUser }
                            userImage={ activeReview.imageUser }
                            nameUserRu={ activeReview.nameUserRu }
                            messageUserRu={ activeReview.messageUserRu }
                            onClose={ handleCloseReview }
                        />
                    )}
                </div>
            </div>
        </>
    );
};

export default Reviews;