import React from "react";
import './CodeScreen.css';

const CodeScreen = () => {
    return (
        <div className="codeScreen_container section">
            <img src={ require( '../../images/code.png' ) } className="codeScreen_image" alt="" />
        </div>
    );
};

export default CodeScreen;