import React from 'react';
import './Home.css';

function Home() {
    return (
        <div className="home-container section" id="home">
            <img src={require("../../images/bg.png")} className='bg-image' alt="" />
            <div className="bg-home"></div>
            <div className="home_content">
                <div></div>
                <div className="home_content_title home_content_title_en">
                    <h1>Modern solutions<br />for the digital age</h1>
                    <h3>We make the digital world accessible to everyone and help you achieve success and grow</h3>
                </div>
                <div className="home_content_title home_content_title_ru displayNone">
                    <h1>Современные решения<br />для цифровой эпохи</h1>
                    <h3>Мы делаем цифровой мир доступным для всех и помогаем вам достичь успеха и расти</h3>
                </div>
                <div className="home_content_scroll">
                    <div className="line line1"></div>
                    <div className="mouse">
                        <img src={require("../../images/mouse.png")} alt="" />
                    </div>
                    <div className="line line2"></div>
                </div>
            </div>
        </div>
    );
};

export default Home;