export const TECHNOLOGIES = [
    {
        title: "Bootstrap",
        image: "bootstrap.png",
    },
    {
        title: "WordPress",
        image: "wordpress.png",
    },
    {
        title: "React",
        image: "react.png",
    },
    {
        title: "Tailwind",
        image: "tailwind.png",
    },
    {
        title: "npm",
        image: "npm.png",
    },
    {
        title: "Drupal",
        image: "drupal.png",
    },
    {
        title: "Vite",
        image: "vite.png",
    },
    {
        title: "Angular",
        image: "angular.png",
    },
    {
        title: "Git",
        image: "git.png",
    },
    {
        title: "MySQL",
        image: "mysql.png",
    },
    {
        title: "Figma",
        image: "figma.png",
    },
    {
        title: "Firebase",
        image: "firebase.png",
    },
    {
        title: "Shopify",
        image: "shopify.png",
    },
    {
        title: "Vue",
        image: "vue.png",
    },
    {
        title: "Cypress",
        image: "cypress.png",
    },
    {
        title: "Modx",
        image: "modx.png",
    },
    {
        title: "MongoDB",
        image: "mongoDB.png",
    },
    {
        title: "Typescript",
        image: "typescript.png",
    },
];

export const STACKS = [
    {
        title: "UX/UI Design",
        percent: 48,
        num: '1',
        stacks: [
            {stack: 'Figma', percentStack: 68},
            {stack: 'Pixso', percentStack: 68},
            {stack: 'Adobe XD', percentStack: 0},
            {stack: 'Sketch', percentStack: 0},
            {stack: 'UI Kit', percentStack: 0},
            {stack: 'Adobe Photoshop', percentStack: 17},
        ]
    },
    {
        title: "Frontend",
        percent: 57,
        num: '2',
        stacks: [
            {stack: 'HTML, CSS, JS', percentStack: 62},
            {stack: 'React', percentStack: 34},
            {stack: 'Angular', percentStack: 12},
            {stack: 'Vue.js', percentStack: 7},
            {stack: 'Python', percentStack: 35},
            {stack: 'Node.js', percentStack: 27},
        ]
    },
    {
        title: "Backend",
        percent: 43,
        num: '3',
        stacks: [
            {stack: 'PHP', percentStack: 53},
            {stack: 'MySQL', percentStack: 53},
            {stack: 'Firebase', percentStack: 3},
            {stack: 'JavaScript', percentStack: 36},
            {stack: 'Python', percentStack: 35},
        ]
    },
    {
        title: "Mobile",
        percent: 5,
        num: '4',
        stacks: [
            {stack: 'React Native', percentStack: 10},
            {stack: 'Java', percentStack: 0},
            {stack: 'C++', percentStack: 1},
            {stack: 'Python', percentStack: 7},
            {stack: 'Flutter + Dart', percentStack: 3},
            {stack: 'Kotlin', percentStack: 0},
        ]
    },
    {
        title: "3D",
        percent: 40,
        num: '5',
        stacks: [
            {stack: '3Ds Max', percentStack: 43},
            {stack: 'Blender', percentStack: 14},
            {stack: 'Corona Render', percentStack: 65},
            {stack: '3D Hamster', percentStack: 70},
            {stack: 'Adobe Photoshop', percentStack: 17},
        ]
    },
    {
        title: "Tools",
        percent: 36,
        num: '6',
        stacks: [
            {stack: 'VS Code', percentStack: 89},
            {stack: 'Git & GitHub', percentStack: 56},
            {stack: 'OS Windows', percentStack: 93},
            {stack: 'OS Linux', percentStack: 43},
            {stack: 'Terminal', percentStack: 34},
            {stack: 'Microsoft Office', percentStack: 34},
        ]
    },
];

export const STAGES = [
    {
        title: 'ux/ui design',
        titleRu: 'ux/ui дизайн',
        number: '1',
        image: 'stage_uxuidesign.png',
        about: 'Development of a modern interactive and stylish design layout according to your preferences',
        aboutRu: 'Разработка современного интерактивного и стильного дизайн-макета в соответствии с вашими предпочтениями',
    },
    {
        title: 'development',
        titleRu: 'разработка',
        number: '2',
        image: 'stage_development.png',
        about: 'Development of web applications based on modern concepts and development methodology',
        aboutRu: 'Разработка веб-приложений на основе современных концепций и методологий разработки',
    },
    {
        title: 'testing',
        titleRu: 'тестирование',
        number: '3',
        image: 'stage_testing.png',
        about: 'Testing at every stage of development. Identification of errors and shortcomings, as well as their timely correction',
        aboutRu: 'Тестирование на каждом этапе разработки. Выявление ошибок и недочетов, а также их своевременное исправление',
    },
    {
        title: 'production',
        titleRu: 'продакшн',
        number: '4',
        image: 'stage_production.png',
        about: 'Build and upload the project to the hosting. If necessary, domain and hosting registration',
        aboutRu: 'Сборка и загрузка проекта на хостинг. При необходимости, регистрация домена и хостинга',
    },
]

export const CASES = [
    {
        title: "UX/UI Design",
        numCategory: 1,
        aboutCategory: 'Development of design layouts in the online graphic editor “Figma” in compliance with modern design trends, color combinations and an intuitive interface',
        aboutCategoryRu: 'Разработка дизайн-макетов в онлайн-графическом редакторе “Figma” с соблюдением современных тенденций дизайна, цветовых сочетаний и интуитивно понятного интерфейса',
        cases: [
            {
                caseName: "Dashboard",
                productionImage: 'uxuiDesign/dashboard.png',
                fullImage: 'uxuiDesign/dashboardFull.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/zu6iPZrU0iJUawJv919LNB/Dashboard?node-id=1-2&t=toy0XTENP75Aaq38-0&scaling=scale-down&page-id=0%3A1",
                abouts: [
                    { about: 'Dashboard applications for daily planning. ' },
                    { about: 'The functionality of the application includes a calendar and date with time, notes and tasks, as well as a tracker record with the history of recent routes.' },
                    { about: 'The overall design is clean and modern, with a vibrant color scheme that uses pink and purple shades. The application provides a user-friendly interface for planning and tracking daily activities.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Панель мониторинга приложений для ежедневного планирования.' },
                    { aboutRu: 'Функционал приложения включает в себя календарь и дату со временем, заметки и задачи, а также запись трекера с историей последних маршрутов.' },
                    { aboutRu: 'Общий дизайн чистый и современный, с яркой цветовой гаммой, в которой используются розовые и фиолетовые оттенки. Приложение предоставляет удобный интерфейс для планирования и отслеживания ежедневных действий.' },
                ],
            },
            {
                caseName: "EltaChat",
                productionImage: 'uxuiDesign/eltaChat.png',
                fullImage: 'uxuiDesign/eltaChat.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/Nn3WqtUfEUbn1LWpgXcFXI/Elta-Chat-UX%2FUI?node-id=8-178&t=z5Gz7jOremvbEJIE-0&scaling=scale-down&page-id=0%3A1&starting-point-node-id=8%3A178",
                abouts: [
                    { about: 'This is a mockup of the "EltaChat" mobile messaging app.' },
                    { about: 'The application has all the necessary functionality for comfortable use, namely a profile, chats, flexible settings for changing the font size' },
                    { about: 'EltaChat is an easy-to-use and modern messaging application offering a wide range of functions.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Это макет мобильного приложения для обмена сообщениями "EltaChat".' },
                    { aboutRu: 'Приложение обладает всем необходимым функционалом для комфортного использования, а именно профилем, чатами, гибкими настройками для изменения размера шрифта.' },
                    { aboutRu: 'Eltchat - это простое в использовании и современное приложение для обмена сообщениями, предлагающее широкий спектр функций.' },
                ],
            },
            {
                caseName: "Happy New Year 2024",
                productionImage: 'uxuiDesign/happyNewYear2024.png',
                fullImage: 'uxuiDesign/happyNewYear2024Full.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/xQlriFMhNhEsZknhMWYKxq/New-Year-2024?node-id=10-249&starting-point-node-id=10%3A206&scaling=scale-down",
                abouts: [
                    { about: 'The project is a New Years web page created as part of the New Years webinar from the online school "Yudaev School".' },
                    { about: 'The main goal is to convey the spirit of the most important holiday of the year, immersing users in an atmosphere of warmth and kindness. The main screen is decorated with bright and colorful elements such as a Christmas tree, gingerbread men and Christmas decorations, which creates a festive mood.' },
                    { about: 'You can also notice a navigation menu on the site with sections "Home", "Services", "About us" and "Contacts", which indicates the versatility of the resource. Users can choose the interface language (English or Russian), which makes the site accessible to a wide audience. The bold and noticeable headline "Happy New Year 2024" attracts attention and focuses on the upcoming holiday, inviting visitors to prepare for it and enjoy the New Years rush.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Проект представляет собой новогоднюю веб-страницу, созданную в рамках новогоднего вебинара онлайн-школы "Школа Юдаева".' },
                    { aboutRu: 'Главная цель - передать дух самого главного праздника в году, погрузив пользователей в атмосферу тепла и доброты. Главный экран украшен яркими и красочными элементами, такими как рождественская елка, пряничные человечки и елочные украшения, что создает праздничное настроение.' },
                    { aboutRu: 'Также на сайте можно заметить навигационное меню с разделами "Главная страница", "Услуги", "О нас" и "Контакты", что свидетельствует о многофункциональности ресурса. Пользователи могут выбирать язык интерфейса (английский или русский), что делает сайт доступным для широкой аудитории. Яркий и заметный заголовок "С Новым 2024 годом" привлекает внимание и акцентирует внимание на предстоящем празднике, приглашая посетителей подготовиться к нему и насладиться новогодней суетой.' },
                ],
            },
            {
                caseName: "Cleaning Oil",
                productionImage: 'uxuiDesign/cleaningOil.png',
                fullImage: 'uxuiDesign/cleaningOilFull.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/wSV6OaHAO7P5p4SllWEVug/Oils?node-id=1-2&starting-point-node-id=1%3A2&scaling=scale-down",
                abouts: [
                    { about: 'The project is a landing page created as part of an intensive course from the online school "Yudaev School".' },
                    { about: 'The rigor of the colors and the main image of the engine in the center instantly inform the user about the purpose and importance of the product.' },
                    { about: 'Minimalism in design with the most necessary information provides the user with comfort for the perception of information.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Проект представляет собой целевую страницу, созданную в рамках интенсивного курса от онлайн-школы "Yudaev School".' },
                    { aboutRu: 'Строгость цветовой гаммы и основное изображение двигателя в центре мгновенно информируют пользователя о назначении и важности продукта.' },
                    { aboutRu: 'Минимализм в дизайне с самой необходимой информацией обеспечивает пользователю комфорт для её восприятия.' },
                ],
            },
            {
                caseName: "McLaren 570S",
                productionImage: 'uxuiDesign/mcLaren570S.png',
                fullImage: 'uxuiDesign/mcLaren570SFull.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/WJub1uES8n2Ck0EH5Y7eCv/McLaren-570S?node-id=1-2&t=rktThJ8dmmF0dab1-0&scaling=scale-down&page-id=0%3A1",
                abouts: [
                    { about: 'This is an elegant and modern design of the website of the famous McLaren brand of luxury sports car model 570S.' },
                    { about: "The dark background creates a sense of sophistication and exclusivity, and the bold red text attracts the attention of users. Red particles falling from above give the product a touch of dynamics and energy, complementing the brand's result-oriented image." },
                    { about: "The clean and minimalistic design makes it easier for users to navigate the website. The design effectively reflects McLaren's commitment to creating exceptional automotive experiences, as well as showcasing the iconic brand logo and corporate identity." },
                    { about: 'Overall, the design of this website effectively captures the essence of McLaren, attracting a discerning audience seeking high performance and luxury. The use of a dark background, highlighted in red text and clear layout makes the site visually attractive and informative.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Это элегантный и современный дизайн веб-сайта известного бренда роскошных спортивных автомобилей McLaren модели 570S.' },
                    { aboutRu: 'Темный фон создает ощущение изысканности и эксклюзивности, а жирный красный текст привлекает внимание пользователей. Красные частицы, падающие сверху, придают изделию нотку динамики и энергии, дополняя имидж бренда, ориентированного на результат.' },
                    { aboutRu: "Лаконичный и минималистичный дизайн облегчает пользователям навигацию по веб-сайту. Дизайн эффективно отражает стремление McLaren к созданию исключительных автомобильных впечатлений, а также демонстрирует культовый логотип бренда и фирменный стиль." },
                    { aboutRu: 'В целом, дизайн этого веб-сайта эффективно отражает суть McLaren, привлекая взыскательную аудиторию, стремящуюся к высокой производительности и роскоши. Использование темного фона, выделенного красным текста и четкой компоновки делает сайт визуально привлекательным и информативным.' },
                ],
            },
            {
                caseName: "NFT Market",
                productionImage: 'uxuiDesign/nftMarket.png',
                fullImage: 'uxuiDesign/nftMarketFull.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/vJv9t4DTerMxUU5vQ8B69d/NFT-Market?node-id=1-3&scaling=scale-down",
                abouts: [
                    { about: 'The project is a website related to NFT tokens.' },
                    { about: 'The interface includes various options such as collections, whitelist, discount market, creation NFT, tops and contacts.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Проект представляет собой веб-сайт, связанный с токенами NFT.' },
                    { aboutRu: 'Интерфейс включает в себя различные опции, такие как коллекции, белый список, рынок скидок, список создания, топы и контакты.' },
                ],
            },
            {
                caseName: "AlphaCars",
                productionImage: 'uxuiDesign/alphaCars.png',
                fullImage: 'uxuiDesign/alphaCarsFull.png',
                stacks: [
                    { stack: "Figma" },
                ],
                link: "https://www.figma.com/proto/K0S3QbZHMEDmvnh53pYyJm/Alpha?node-id=1-11&t=tlSEre3FQf07bZt2-0&scaling=min-zoom&page-id=0%3A1",
                abouts: [
                    { about: 'The project is a web page created as part of an intensive course from the online school "Itlogia".' },
                    { about: 'The design layout of the AlphaCars online store, which sells luxury cars, is made in a minimalistic style using white and black, as well as two shades of red.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Проект представляет собой веб-страницу, созданную в рамках интенсивного курса онлайн-школы "Itlogia".' },
                    { aboutRu: 'Дизайн-макет интернет-магазина "AlphaCars", занимающегося продажей автомобилей класса люкс, выполнен в минималистичном стиле с использованием белого и черного цветов, а также двух оттенков красного.' },
                ],
            },
            
        ],
    },
    {
        title: "Web",
        numCategory: 2,
        aboutCategory: 'Development of frontend and backend parts of the application with modern tools and popular languages. Writing program code in accordance with the BEM methodology for comfortable code reading.',
        aboutCategoryRu: 'Разработка интерфейсной и серверной частей приложения с использованием современных инструментов и популярных языков. Написание программного кода в соответствии с методологией BEM для комфортного чтения кода.',
        cases: [
            {
                caseName: 'Luxorides',
                productionImage: 'web/luxorides.png',
                fullImage: 'web/luxoridesFull.png',
                stacks: [
                    { stack: 'Angular' },
                    { stack: 'Typescript' },
                    { stack: 'Git' },
                ],
                link: 'https://devoneoff.github.io/cars-hw/',
                abouts: [
                    { about: 'The strict and stylish design of the online store of the car dealer "Luxorides", made in the same style in black and white tones.' },
                    { about: 'Car data is loaded dynamically from the backend via the API. When you select a car, that is, when you click on the car card, data about the selected option immediately appears in the field of the valid form "The car that interests you".' },
                ],
                aboutsRu: [
                    { aboutRu: 'Строгий и стильный дизайн интернет-магазина автодилера "Luxorides", выполненный в едином стиле в черно-белых тонах.' },
                    { aboutRu: 'Данные об автомобиле загружаются динамически из серверной части через API. При выборе автомобиля, то есть при нажатии на карточку автомобиля, данные о выбранном варианте сразу же появляются в поле действующей формы "Интересующий вас автомобиль".' },
                ]
            },
            {
                caseName: 'Burgers',
                productionImage: 'web/burgers.png',
                fullImage: 'web/burgersFull.png',
                stacks: [
                    { stack: 'Angular' },
                    { stack: 'Typescript' },
                    { stack: 'Git' },
                ],
                link: 'https://devoneoff.github.io/Burgers/',
                abouts: [
                    { about: 'The project is a landing page created as part of an intensive course from the online school "Itlogia".' },
                    { about: 'The hot and juicy design of the online store of the Burger House restaurant, made in the same style in the colors of the burger with pleasant images.' },
                    { about: 'Burger data is loaded dynamically from the backend via the API. When choosing a burger, that is, when clicking on the card, data about the selected option immediately appears in the field of the valid form "Select an item from the list".' },
                ],
                aboutsRu: [
                    { aboutRu: 'Проект представляет собой целевую страницу, созданную в рамках интенсивного курса онлайн-школы "Itlogia".' },
                    { aboutRu: 'Горячий и сочный дизайн интернет-магазина ресторана Burger House, выполненный в едином стиле в цветовой гамме бургера с приятными изображениями.' },
                    { aboutRu: 'Данные о бургере загружаются динамически из серверной части через API. При выборе бургера, то есть при нажатии на карточку, данные о выбранном варианте сразу появляются в поле действующей формы "Выбрать товар из списка".' },
                ],
            },
            {
                caseName: 'Game 2048',
                productionImage: 'web/game2048.png',
                fullImage: 'web/game2048Full.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'JS' },
                ],
                link: 'https://devoneoff.github.io/2048/',
                abouts: [
                    { about: 'The well-known game "2048" is now in a web format, which is controlled via the keyboard with the arrow keys.' },
                    { about: 'Nice colors, high-quality application logic using JS classes.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Хорошо известная игра "2048" теперь представлена в веб-формате, управление которой осуществляется с помощью клавиатуры со стрелками.' },
                    { aboutRu: 'Приятные цвета, качественная логика приложения с использованием JS-классов .' },
                ],
            },
            {
                caseName: '3D Parallax Card',
                productionImage: 'web/3dParallaxCard.png',
                fullImage: 'web/3dParallaxCardFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                ],
                link: 'https://devoneoff.github.io/3dParallaxCard/',
                abouts: [
                    { about: 'In general, using HTML and CSS, you can create interesting and unique things. A 3D effect map is one of the interesting things implemented in these languages.' },
                    { about: 'When you hover over the map, a 3D effect occurs that will not leave anyone unattended.' },
                ],
                aboutsRu: [
                    { aboutRu: 'В целом, используя HTML и CSS, вы можете создавать интересные и уникальные вещи. Карта с 3Д-эффектом - одна из интересных вещей, реализованных в этих языках.' },
                    { aboutRu: 'При наведении курсора на карту возникает 3D-эффект, который никого не оставит без внимания.' },
                ],
            },
            {
                caseName: 'Water Drop Effect',
                productionImage: 'web/waterDropEffect.png',
                fullImage: 'web/waterDropEffectFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                ],
                link: 'https://devoneoff.github.io/WaterDropEffect/',
                abouts: [
                    { about: 'In total, using HTML and CSS, you can do interesting and unique things. A 3D drop of water is one of the interesting things these languages implement.' },
                    { about: 'When you hover over a drop, a 3D effect occurs that will not leave anyone unattended.' },
                ],
                aboutsRu: [
                    { aboutRu: 'В целом, используя HTML и CSS, вы можете создавать интересные и уникальные вещи. 3Д капля воды - одна из интересных вещей, реализованных в этих языках.' },
                    { aboutRu: 'Когда вы наводите курсор на каплю, возникает 3D-эффект, который никого не оставит без внимания.' },
                ]
            },
            {
                caseName: 'Weather App',
                productionImage: 'web/weatherApp.png',
                fullImage: 'web/weatherAppFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'JS' },
                    { stack: 'API' },
                ],
                link: 'https://devoneoff.github.io/weather-app/',
                abouts: [
                    { about: 'Knowledge of HTML, CSS and JS, as well as the API service, is what is needed to create a simple weather forecasting application.' },
                    { about: 'Stylish and atypical design, simple functionality, and intuitive interface attract users to use the application conveniently.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Знание HTML, CSS и JS, а также API-сервиса - вот что необходимо для создания простого приложения для прогнозирования погоды.' },
                    { aboutRu: 'Стильный и нетипичный дизайн, простая функциональность и интуитивно понятный интерфейс привлекают пользователей к удобному использованию приложения.' },
                ],
            },
            {
                caseName: 'Sidebar',
                productionImage: 'web/sidebar.png',
                fullImage: 'web/sidebarFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'React' },
                ],
                link: 'https://devoneoff.github.io/Sidebar-Black/',
                abouts: [
                    { about: 'This is not a website or a web application, or even a web page. This is a separate element of the web resource, namely the sidebar.' },
                    { about: "The sidebar has smooth animations, hiding and revealing menu items, as well as a user drop-down window. From the user's point of view, such an element, in most cases, is very convenient due to its compactness." },
                ],
                aboutsRu: [
                    { aboutRu: 'Это не веб-сайт, не веб-приложение и даже не веб-страница. Это отдельный элемент веб-ресурса, а именно боковая панель.' },
                    { aboutRu: "Боковая панель имеет плавную анимацию, скрывающую и раскрывающую пункты меню, а также выпадающее окно для пользователя. С точки зрения пользователя, такой элемент в большинстве случаев очень удобен из-за своей компактности." },
                ],
            },
            {
                caseName: 'Calendar',
                productionImage: 'web/calendar.png',
                fullImage: 'web/calendarFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'JS' },
                ],
                link: 'https://devoneoff.github.io/Calendar/',
                abouts: [
                    { about: 'Knowledge of HTML, CSS and JS, as well as the API service, is what is needed to create a simple calendar.' },
                    { about: 'Stylish and atypical design, simple functionality and intuitive interface attract users to use the application conveniently.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Знание HTML, CSS и JS, а также API-сервиса - это то, что необходимо для создания простого календаря.' },
                    { aboutRu: 'Стильный и нетипичный дизайн, простая функциональность и интуитивно понятный интерфейс привлекают пользователей к удобному использованию приложения.' },
                ]
            },
            {
                caseName: 'Natural Forest',
                productionImage: 'web/naturalForest.png',
                fullImage: 'web/naturalForestFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'JS' },
                ],
                link: 'https://devoneoff.github.io/natural-forest/',
                abouts: [
                    { about: 'What is better than a unified connection with nature, a sense of the reality of objects and joint interaction?' },
                    { about: 'Parallax is one of these ways that allow you to plunge into the space of the page as if into a window.' },
                    { about: 'This project is simple, but it allows the user to relax not only due to the 3D effect of the forest, but also realistic rain.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Что может быть лучше единой связи с природой, ощущения реальности объектов и совместного взаимодействия?' },
                    { aboutRu: 'Параллакс - это один из таких способов, который позволяет вам погрузиться в пространство страницы, как будто в окно.' },
                    { aboutRu: 'Этот проект простой, но он позволяет пользователю расслабиться не только за счет 3D-эффекта леса, но и реалистичного дождя.' },
                ]
            },
            {
                caseName: 'HoodieMantle',
                productionImage: 'web/hoodieMantle.png',
                fullImage: 'web/hoodieMantleFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'Bootstrap' },
                    { stack: 'JS, jQuery, swiper' },
                ],
                link: 'https://devoneoff.github.io/slider-3c-scene/',
                abouts: [
                    { about: 'What attracts users to 3D objects in the modern world? The ability to inspect objects from all sides. The more realistic the object, the more mesmerizing the effect.' },
                    { about: 'Hoodie Mantle is an educational project based on a video lesson. The development process itself was fun, and the result was admirable.' },
                ],
                aboutsRu: [
                    { aboutRu: 'Что привлекает пользователей в 3D-объектах в современном мире? Возможность рассматривать объекты со всех сторон. Чем реалистичнее объект, тем более завораживающий эффект он производит.' },
                    { aboutRu: 'Hoodie Mantle - это образовательный проект, основанный на видеоуроке. Сам процесс разработки был увлекательным, а результат - достойным восхищения.' },
                ],
            },
            {
                caseName: 'Slider Nature',
                productionImage: 'web/sliderNature.png',
                fullImage: 'web/sliderNatureFull.png',
                stacks: [
                    { stack: 'HTML' },
                    { stack: 'CSS' },
                    { stack: 'JS' },
                ],
                link: 'https://devoneoff.github.io/slider-nature/',
                abouts: [
                    { about: "Nowadays, with the most recent and progressive developments, the use of sliders is unlikely to surprise anyone. Therefore, it's time to experiment." },
                    { about: 'Each image has its own description, which appears only when the slide becomes active. The slider is looped and has a nice preview of subsequent slides.' },
                ],
                aboutsRu: [
                    { aboutRu: "В наше время, при самых последних и прогрессивных разработках, использование слайдеров вряд ли кого-то удивит. Поэтому самое время экспериментов." },
                    { aboutRu: 'Каждое изображение имеет свое собственное описание, которое появляется только тогда, когда слайд становится активным. Слайдер перемещается по кругу и обеспечивает приятный предварительный просмотр последующих слайдов.' },
                ],
            },
        ],
    },
    {
        title: "Mobile",
        numCategory: 3,
        aboutCategory: 'Development of mobile cross-platform applications in modern ways.',
        aboutCategoryRu: 'Разработка мобильных кроссплатформенных приложений современными способами',
        cases: [],
    },
    {
        title: "3D",
        numCategory: 4,
        aboutCategory: 'Modeling of objects, visualization of the interior, without forgetting about the style. Thinking through all the little things from the type of doors to the outlet options.',
        aboutCategoryRu: 'Моделирование объектов, визуализация интерьера, не забывая при этом о стиле. Продумывание всех мелочей от типа дверей до вариантов розеток.',
        cases: [
            {
                caseName: 'Children Room',
                productionImage: '3d/childrenRoom.png',
                fullImage: '3d/childrenRoomFull.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: '3d/childrenRoomFull.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Chair',
                productionImage: '3d/chair.png',
                fullImage: '3d/chairFull.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: '3d/chairFull.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Breakfast',
                productionImage: '3d/breakfast.png',
                fullImage: '3d/breakfast.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: '3d/breakfast.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Kitchen',
                productionImage: '3d/kitchen.png',
                fullImage: '3d/kitchen.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: '3d/kitchen.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Elevator',
                productionImage: '3d/elevator.png',
                fullImage: '3d/elevator.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: 'elevator.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Living Room',
                productionImage: '3d/livingRoomOne.png',
                fullImage: '3d/livingRoomOne.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: 'livingRoomOne.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Toilet',
                productionImage: '3d/toilet.png',
                fullImage: '3d/toilet.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: 'toilet.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'House',
                productionImage: '3d/house.png',
                fullImage: '3d/house.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: 'house.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
            {
                caseName: 'Bed Room',
                productionImage: '3d/bedRoom.png',
                fullImage: '3d/bedRoom.png',
                stacks: [
                    { stack: '3Ds Max' },
                    { stack: 'Corona Render' },
                    { stack: '3DHamster' },
                ],
                link: 'bedRoom.png',
                abouts: [
                    { about: '' },
                    { about: '' },
                    { about: '' },
                ]
            },
        ],
    },
    {
        title: "Graphics",
        numCategory: 5,
        aboutCategory: 'Rendering of various raster and vector images',
        aboutCategoryRu: 'Рендеринг различных растровых и векторных изображений',
        cases: [],
    },
];

export const REVIEWS = [
    {
        idUser: 1,
        nameUser: 'Nika',
        nameUserRu: 'Ника',
        imageUser: 'user1.png',
        messageUser: 'Excellent performer 👍 Everything is done competently and on time 🤗 I recommend you to cooperate 🤝🌸 🌸 🌸',
        messageUserRu: 'Отличный исполнитель 👍 Всё сделано грамотно и в срок 🤗 Рекомендую к сотрудничеству 🤝🌸 🌸 🌸',
    },
    {
        idUser: 2,
        nameUser: 'Evgeniy',
        nameUserRu: 'Евгений',
        imageUser: 'user2.png',
        messageUser: 'The task was completed according to the instructions, on time. Thanks for the work!',
        messageUserRu: 'Задание выполнено по инструкции, в срок. Спасибо за работу!',
    },
    {
        idUser: 3,
        nameUser: 'InPro',
        nameUserRu: 'InPro',
        imageUser: 'user3.png',
        messageUser: 'Great job. Carefully, quickly, everything is on the case! We highly recommend it',
        messageUserRu: 'Отличая работа. Аккуратно, быстро, все по делу! Очень рекомендуем',
    },
    {
        idUser: 4,
        nameUser: 'Natalya',
        nameUserRu: 'Наталья',
        imageUser: 'user4.png',
        messageUser: 'I applied to create a website with my favorite recipes so that they would be in one place. Everything is clear, according to sections, cards for each recipe. You can add new ones as needed! I really like it! Thanks!',
        messageUserRu: 'Обратилась для создания сайта с любимым рецептами, чтобы были в одном месте. Все четко, по разделам, карточки на каждый рецепт. Можно добавлять по мере необходимости новые! Очень нравится! Спасибо!',
    }
]

export const SOCIALS = [
    {
        title: 'GitHub',
        numSocial: 1,
        image: 'github_original.png',
        imageWhite: 'github_white.png',
        imageBlack: 'github_black.png',
        link: 'https://github.com/devoneoff',
    },
    {
        title: 'Behance',
        numSocial: 2,
        image: 'behance_original.png',
        imageWhite: 'behance_white.png',
        imageBlack: 'behance_black.png',
        link: 'https://www.behance.net/devoneoff',
    },
    {
        title: 'VK',
        numSocial: 3,
        image: 'vk_original.png',
        imageWhite: 'vk_white.png',
        imageBlack: 'vk_black.png',
        link: 'https://vk.com/devoneoff',
    },
    {
        title: 'Zen',
        numSocial: 4,
        image: 'zen_original.png',
        imageWhite: 'zen_white.png',
        imageBlack: 'zen_black.png',
        link: 'https://dzen.ru/devoneoff',
    },
    {
        title: 'Kwork',
        numSocial: 5,
        image: 'kwork_original.png',
        imageWhite: 'kwork_white.png',
        imageBlack: 'kwork_black.png',
        link: 'https://kwork.ru/user/devoneoff3',
    },
    {
        title: 'Telegram',
        numSocial: 6,
        image: 'telegram_original.png',
        imageWhite: 'telegram_white.png',
        imageBlack: 'telegram_black.png',
        link: '',
    },
    {
        title: 'HH.RU',
        numSocial: 7,
        image: 'hhru_original.png',
        imageWhite: 'hhru_white.png',
        imageBlack: 'hhru_black.png',
        link: 'https://hh.ru/resume/6066200aff09b6e13d0039ed1f346175416e31',
    },
    {
        title: 'Stepik',
        numSocial: 8,
        image: 'stepik_original.png',
        imageWhite: 'stepik_white.png',
        imageBlack: 'stepik_black.png',
        link: '',
    },
    
]