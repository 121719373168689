import React from "react";
import './CaseActive.css';

const CaseActive = ( { caseData, onClose } ) => {
    return (
        <div className="caseActive_container">
            <div className="caseActive_contentBlock">
                <div className="caseActive_title">
                    <button
                        className="caseActive_buttonClose"
                        onClick={ onClose }
                    >
                        <span className="material-symbols-rounded">close_small</span>
                    </button>
                </div>
                <div className="caseActive_content">
                    <div className="caseActive_imageAndTitle">
                        <div className="caseActive_imageAndTitle_image">
                            <img src={ require(`../../../images/projects/${caseData.fullImage}`) } alt={ caseData.caseName } />
                        </div>
                        <div className="caseActive_imageAndTitle_title">
                            <div className="blockAndTitles">
                                <div className="caseActive_imageAndTitle_title_item">
                                    <div className="caseActive_imageAndTitle_title_item_main">Name</div>
                                    <div className="caseActive_imageAndTitle_title_item_name">{ caseData.caseName }</div>
                                </div>
                                <div className="caseActive_imageAndTitle_title_item">
                                    <div className="caseActive_imageAndTitle_title_item_main">Stack</div>
                                    <div className="caseActive_imageAndTitle_title_item_stacks">
                                        { caseData.stacks.map( (caseStackActive, caseStackActiveIndex) => (
                                            <div className="caseActive_imageAndTitle_title_item_stack" key={ `caseStackActive_${caseStackActiveIndex}` }>
                                                <span>{ caseStackActive.stack }</span>
                                            </div>
                                        ) )}
                                    </div>
                                </div>
                            </div>
                            <div className="caseActive_imageAndTitle_title_button">
                                <a href={ caseData.link } target="_blank" className="caseActive_imageAndTitle_title_button_link caseActive_imageAndTitle_title_button_link_en">Follow the link<img src={ require( '../../../images/linkProject.png' ) } alt="l" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="caseActive_abouts">
                        { caseData.abouts.map( (caseStack, caseStackIndex) => (
                            <div className="caseActive_about" key={ `aboutText_${caseStackIndex}` }>
                                { caseStack.about }
                            </div>
                        ) )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CaseActive;