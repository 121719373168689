import React from 'react';
import './StagesOfImplementation.css';

const StagesOfImplementation = () => {
    return (
        <div className="stagesOfImplementation_container section">
            <div className="stagesOfImplementation_title stagesOfImplementation_title_en">Stages of the project implementation</div>
            <div className="stagesOfImplementation_title stagesOfImplementation_title_ru displayNone">Этапы реализации проекта</div>
            <div className="stagesOfImplementation_content">
                
                <div className="stagesOfImplementation_content_item">
                    <div className="stagesOfImplementation_item_image">
                        <img src={ require( '../../images/stageUxUiDesign.png' ) } alt="" />
                    </div>
                    <div className="stagesOfImplementation_item_info">
                        <div className='stagesOfImplementation_item_info_lineOne left one'>
                            <div className='stagesImpLineOne1'></div>
                            <div className='stagesImpLineOne2'></div>
                            <div className='stagesImpLineOne3'></div>
                            <div className='stagesImpLineOne4'></div>
                            <div className='stagesImpLineOne5'></div>
                        </div>
                        <div className='stagesOfImplementation_item_info_content'>
                            <div className='stagesOfImplementation_item_info_lineTwo one'></div>
                            <div className="stagesOfImplementation_item_info_content_title one stagesOfImplementation_item_info_content_title1_en">Design layout</div>
                            <div className="stagesOfImplementation_item_info_content_title one stagesOfImplementation_item_info_content_title1_ru displayNone">Дизайн-макет</div>
                            <div className="stagesOfImplementation_item_info_content_text one stagesOfImplementation_item_info_content_text1_en">Creating the design of your future website or application. Thinking through the concept, the color scheme, the content of the content and all other small details</div>
                            <div className="stagesOfImplementation_item_info_content_text one stagesOfImplementation_item_info_content_text1_ru displayNone">Создаем дизайн вашего будущего веб-сайта или приложения. Продумываем концепцию, цветовую гамму, содержание контента и все другие мелкие детали</div>
                        </div>
                    </div>
                </div>
                
                <div className="stagesOfImplementation_content_item">
                    <div className="stagesOfImplementation_item_info">
                        <div className='stagesOfImplementation_item_info_content right'>
                            <div className='stagesOfImplementation_item_info_lineTwo two'></div>
                            <div className="stagesOfImplementation_item_info_content_title right two stagesOfImplementation_item_info_content_title2_en">Layout and programming</div>
                            <div className="stagesOfImplementation_item_info_content_title right two stagesOfImplementation_item_info_content_title2_ru displayNone">Верстка и программирование</div>
                            <div className="stagesOfImplementation_item_info_content_text right two stagesOfImplementation_item_info_content_text2_en">Creating page markup and components, adding interactive elements, writing program code to implement the functionality of a website or application</div>
                            <div className="stagesOfImplementation_item_info_content_text right two stagesOfImplementation_item_info_content_text2_ru displayNone">Создание разметки страниц и компонентов, добавление интерактивных элементов, написание программного кода для реализации функциональности веб-сайта или приложения</div>
                        </div>
                        <div className='stagesOfImplementation_item_info_lineOne right two'>
                            <div className='stagesImpLineTwo1'></div>
                            <div className='stagesImpLineTwo2'></div>
                            <div className='stagesImpLineTwo3'></div>
                            <div className='stagesImpLineTwo4'></div>
                            <div className='stagesImpLineTwo5'></div>
                        </div>
                    </div>
                    <div className="stagesOfImplementation_item_image right">
                        <img src={ require( '../../images/stageDevelopment.png' ) } alt="" />
                    </div>
                </div>

                <div className="stagesOfImplementation_content_item">
                    <div className="stagesOfImplementation_item_image">
                        <img src={ require( '../../images/stageTesting.png') } />
                    </div>
                    <div className="stagesOfImplementation_item_info">
                        <div className='stagesOfImplementation_item_info_lineOne left three'>
                            <div className='stagesImpLineThree1'></div>
                            <div className='stagesImpLineThree2'></div>
                            <div className='stagesImpLineThree3'></div>
                            <div className='stagesImpLineThree4'></div>
                            <div className='stagesImpLineThree5'></div>
                        </div>
                        <div className='stagesOfImplementation_item_info_content'>
                            <div className='stagesOfImplementation_item_info_lineTwo three'></div>
                            <div className="stagesOfImplementation_item_info_content_title three stagesOfImplementation_item_info_content_title3_en">Testing</div>
                            <div className="stagesOfImplementation_item_info_content_title three stagesOfImplementation_item_info_content_title3_ru displayNone">Тестирование</div>
                            <div className="stagesOfImplementation_item_info_content_text three stagesOfImplementation_item_info_content_text3_en">Conducting test work to identify errors and bugs and their further correction. Research the entire code and, if necessary, finalize new ideas and edit current content.</div>
                            <div className="stagesOfImplementation_item_info_content_text three stagesOfImplementation_item_info_content_text3_ru displayNone">Проведение тестовых работ с целью выявления ошибок и недочетов и их дальнейшего исправления. Изучите весь код и, при необходимости, доработайте новые идеи и отредактируйте текущее содержимое.</div>
                        </div>
                    </div>
                </div>

                <div className="stagesOfImplementation_content_item">
                    <div className="stagesOfImplementation_item_info">
                        <div className='stagesOfImplementation_item_info_content right'>
                            <div className='stagesOfImplementation_item_info_lineTwo four'></div>
                            <div className="stagesOfImplementation_item_info_content_title right four stagesOfImplementation_item_info_content_title4_en">Production</div>
                            <div className="stagesOfImplementation_item_info_content_title right four stagesOfImplementation_item_info_content_title4_ru displayNone">Продакшн</div>
                            <div className="stagesOfImplementation_item_info_content_text right four stagesOfImplementation_item_info_content_text4_en">Checking the availability of all necessary files and media and preparing for assembly. Building the project and publishing it on the hosting. If necessary, help with hosting registration and domain registration.</div>
                            <div className="stagesOfImplementation_item_info_content_text right four stagesOfImplementation_item_info_content_text4_ru displayNone">Проверка наличия всех необходимых файлов и носителей и подготовка к сборке. Создаем проект и размещаем его на хостинге. При необходимости помогаем с регистрацией хостинга и домена.</div>
                        </div>
                        <div className='stagesOfImplementation_item_info_lineOne right four'>
                            <div className='stagesImpLineFour1'></div>
                            <div className='stagesImpLineFour2'></div>
                            <div className='stagesImpLineFour3'></div>
                            <div className='stagesImpLineFour4'></div>
                            <div className='stagesImpLineFour5'></div>
                        </div>
                    </div>
                    <div className="stagesOfImplementation_item_image right">
                        <img src={ require( '../../images/stageProduction.png' ) } alt="" />
                    </div>
                </div>
            </div>
            <div className="stagesOfImplementation_attention">
                <div className="attention_title"><span>a</span><span>t</span><span>t</span><span>e</span><span>n</span><span>t</span><span>i</span><span>o</span><span>n</span></div>
                <div className="attention_text attention_text_en">I keep in touch with the customer at every stage of the project. I periodically send files for verification and only after verification I proceed to continue the development process. All I need from you is a clearly formulated and stated technical task. After careful study, we will discuss all the conditions with you: the stack of technologies and languages used, the development platform, deadlines, the cost of each stage and the total cost of the project, additional necessary services (help or advice).</div>
                <div className="attention_text attention_text_ru displayNone">Я поддерживаю связь с заказчиком на каждом этапе проекта. Я периодически отправляю файлы на проверку и только после проверки приступаю к продолжению процесса разработки. Все, что мне нужно от Вас, - это четко сформулированное техническое задание. После тщательного изучения мы обсудим с Вами все условия: набор используемых технологий и языков, платформу разработки, сроки, стоимость каждого этапа и общую стоимость проекта, дополнительные необходимые услуги (помощь или консультацию).</div>
                <div className="attention_title"><span>a</span><span>t</span><span>t</span><span>e</span><span>n</span><span>t</span><span>i</span><span>o</span><span>n</span></div>
            </div>
        </div>
    );
};

export default StagesOfImplementation;