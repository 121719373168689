import React from 'react';
import './CaseTitleCard.css';

const CaseTitleCard = ( { title, isActive, onClick, numCategory } ) => {
    return (
        <div 
            className={ `caseTitleCard_container ${isActive ? "active" : "" } caseTitleCard_container${ numCategory }` } 
            onClick={ () => onClick() }
        >
            <span>{title}</span>
        </div>
    );
};

export default CaseTitleCard;