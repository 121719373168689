import React, { useState } from "react";
import './Navbar.css';
import ButtonTheme from "../ButtonTheme/ButtonTheme";
import MobileNav from "../MobileNav/MobileNav";
import ButtonLanguage from "../ButtonLanguage/ButtonLanguage";

const  Navbar = () => {

    const [ openMenu, setOpenMenu ] = useState( false );
    const toggleMenu = () => {
        setOpenMenu( !openMenu );
        console.log('открыто меню');
        document.querySelector('.language').classList.toggle('active');
    };

    return (
        <>
            <MobileNav isOpen={openMenu} toggleMenu={toggleMenu} />
            <nav className="nav_wrapper">
                <div className="nav_content">
                    <div className="nav_logo">
                        <div className="nav_logo_image">
                            <img src={require('../../images/logo.png')} alt="" />
                        </div>
                        <div className="nav_logo_text">
                            DEVONE<span className="gradientText">.OFF</span>
                        </div>
                    </div>
                    <ul className="ul_en">
                        <li><a href="#home" className="menu_itemOne menu_itemOne_en" id="menu_itemOne">Home</a></li>
                        <li><a href="#about" className="menu_itemTwo menu_itemTwo_en" id="menu_itemTwo">About</a></li>
                        <li><a href="#stack" className="menu_itemThree menu_itemThree_en" id="menu_itemThree">Stack</a></li>
                        <li><a href="#case" className="menu_itemFour menu_itemFour_en" id="menu_itemFour">Case</a></li>
                        <li><a href="#contact" className="menu_itemFive menu_itemFive_en" id="menu_itemFive">Contact</a></li>
                    </ul>
                    <ul className="ul_ru displayNone">
                        <li><a href="#home" className="menu_itemOne menu_itemOne_ru displayNone" id="menu_itemOne">Главная</a></li>
                        <li><a href="#about" className="menu_itemTwo menu_itemTwo_ru displayNone" id="menu_itemTwo">О Себе</a></li>
                        <li><a href="#stack" className="menu_itemThree menu_itemThree_ru displayNone" id="menu_itemThree">Стек</a></li>
                        <li><a href="#case" className="menu_itemFour menu_itemFour_ru displayNone" id="menu_itemFour">Кейс</a></li>
                        <li><a href="#contact" className="menu_itemFive menu_itemFive_ru displayNone" id="menu_itemFive">Контакты</a></li>
                    </ul>
                    <div className="languagetheme">
                        <ButtonLanguage />
                        <ButtonTheme />
                    </div>
                    <button className="menu-btn" onClick={ toggleMenu }>
                        <span
                            className="material-symbols-outlined"
                            style={{fontSize: "1.8rem"}}
                        >
                            { openMenu ? "close" : "menu" }
                        </span>
                    </button>
                </div>
            </nav>
        </>
    );
};

export default Navbar;