import React from "react";
import './Contact.css';
import ContactSocialLink from "./ContactSocialLink/ContactSocialLink";
import { SOCIALS } from "../../utils/data";
import ContactForm from "./ContactForm/ContactForm";

const Contact = () => {
    return (
        <div className="contact_container section" id="contact">
            <div className="contact_content_Form">
                <div className="contact_content">
                    <p className="contact_content_p1 contact_content_p1_en">// To clarify my level of proficiency in any technology or languages, please contact me through the application form, specifying the category "Consultation".</p>
                    <p className="contact_content_p1 contact_content_p1_ru displayNone">// Чтобы уточнить мой уровень владения какой-либо технологией или языками, пожалуйста, свяжитесь со мной через форму заявки, указав категорию "Консультация".</p>
                    <p className="contact_content_p2 contact_content_p2_en">To order or clarify the details of a project, when filling out the form, select the appropriate category for your project.</p>
                    <p className="contact_content_p2 contact_content_p2_ru displayNone">Чтобы заказать или уточнить детали проекта, при заполнении формы выберите соответствующую категорию для вашего проекта.</p>
                </div>
                <div className="contact_form">
                    <div className="contact_form_title contact_form_title_en">// Get in touch</div>
                    <div className="contact_form_title contact_form_title_ru displayNone">// Связаться</div>
                    <div className="contact_form_form">
                        <ContactForm />
                    </div>
                </div>
            </div>
            <div className="contact_content_info">
                <p className="contact_content_info_p contact_content_info_p_en">// Build using Figma, React, Tailwind, Netlify, Vue, Bootstrap, MySQL, WordPress, Less and etc.</p>
                <p className="contact_content_info_p contact_content_info_p_ru displayNone">// Создаю с использованием Figma, React, Tailwind, Netlify, Vue, Bootstrap, MySQL, WordPress, Less и т.д.</p>
            </div>
            <div className="contact_social">
                <div className="contact_social_content">
                    { SOCIALS.map( ( item ) => (
                        <ContactSocialLink
                            key={ item.title }
                            title={ item.title }
                            numSocial={ item.numSocial }
                            image={ item.image }
                            link={ item.link }
                            imageWhite={ item.imageWhite }
                            imageBlack={ item.imageBlack }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Contact;