import React from "react";
import './Footer.css';

const Footer = () => {

    return (
        <div className="footer_container">
            <div className="footer_content">
                <div className="footer_column">
                    © 2021 -2024 DEVONE<span className="gradientText">.OFF</span>
                </div>
                <div className="footer_column policy_en">Privacy Policy</div>
                <div className="footer_column policy_ru displayNone">Политика конфиденциальности</div>
                <div className="footer_column up_en">
                    <a href="#">
                        up 
                        <span className="material-symbols-rounded">arrow_upward_alt</span>
                    </a>
                </div>
                <div className="footer_column up_ru displayNone">
                    <a href="#">
                        наверх 
                        <span className="material-symbols-rounded">arrow_upward_alt</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Footer;